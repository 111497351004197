<template>
  <CCard>
    <CCardHeader>
      <h3>Ver Categoría</h3>
    </CCardHeader>
    <CCardBody>
      <div class="form-group">
        <label>Categoria:</label>
        <p>{{ registro.categoria }}</p>
      </div>
      <div class="form-group">
        <label>slug:</label>
        <p>{{ registro.slug }}</p>
      </div>
      <div class="form-group">
        <label>Idioma:</label>
        <p>{{ registro.idioma }}</p>
      </div>
      <div class="form-group">
        <label>Descripción:</label>
        <p>{{ registro.descripcion }}</p>
      </div>
      <div class="form-group">
        <router-link to="/ecoCategorias/List">
          <CButton color="secondary">
            <i class="cil-arrow-circle-left"></i>
            Regresar
          </CButton>
        </router-link>
      </div>
    </CCardBody>
  </CCard>
</template>
<script>
import httpService from "@/services/httpService"

export default {
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {
        id: '',
        grupo: '',
        tipo: '',
        created_at: '',
        updated_at: '',
        delete_at: '',
        textura: '',
        orden: '',
        color: '',
        codigo: '',
        idioma: '',
      }
    }
  },

  methods: {
    async getRegistro() {

      this.id = this.$route.params.id;
      if (this.id !== '0') {
        const url ='eco-categorias/' + this.id;
        let response = await httpService.get(url);
        let respuesta = response.data;
        this.registro = respuesta.registros[0];
      }
    },
  },

  mounted() {
    this.getRegistro();
  }

}
</script>